// src/components/EmojiPicker.js
import React from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { useRef } from "react";

const EmojiPicker = ({ onEmojiSelect, callBackFn }) => {
  const pickerRef = useRef(null);

  useOnClickOutside(pickerRef, () => {
    callBackFn();
  });
  return (
    <div ref={pickerRef}>
      <Picker
        classNam="emoji-mart "
        data={data}
        onEmojiSelect={onEmojiSelect}
        perLine={8}
        maxFrequentRows={1}
        previewPosition="none"
        skinTonePosition="none"
      />
    </div>
  );
};

export default EmojiPicker;
