import React from "react";
import { Image } from "antd";
import Appstore from "../assets/appstore-com.png";
import Googleplay from "../assets/googleplay_ecom.png";
import Sos from "../assets/svg/icon_sos.png";
import Phone from "../assets/svg/imgpsh_fullsize_anim-2.png";
import { useNavigate } from "react-router-dom";
import { APP_URL } from "../utils/constant";

const TopContainer = () => {
  const navigate = useNavigate();
  return (
    <div className="app-top-container">
      <div className="app-container ">
        <div className="download-app">
          <span className="down-title">Bấm để tải app Giaohangtietkiem :</span>
          <div className="down-icon">
            <Image
              className="appstore"
              src={Appstore}
              preview={false}
              alt="logo"
            />
            <Image
              className="googleplay"
              src={Googleplay}
              preview={false}
              alt="logo"
            />
          </div>
        </div>
        <div className="cskh-block">
          <div
            className="cskh-btn"
            onClick={() => {
              // navigate("/sos-ghtk");
              window.open(`${APP_URL}/sos-ghtk`, "_blank");
            }}
          >
            <Image
              className="cskh-btn-icon"
              src={Sos}
              preview={false}
              alt="logo"
            />
            <span>
              Cần hỗ trợ? <b>CHAT NGAY!</b>
            </span>
          </div>
          {/* <div className="cskh-btn">
            <Image
              className="cskh-btn-icon"
              src={Phone}
              preview={false}
              alt="logo"
            />
            <span>
              <b>Hotline</b>{' '}
              <b>1900 6092</b>
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TopContainer;
