import { useEffect, useRef, useState } from 'react';
import socketIOClient from 'socket.io-client';
import api from '../api';
import * as actions from '../redux/actions/auth';
import { SOCKET_SERVER_URL } from '../utils/constant';
import { generateDeviceID } from '../utils/common';

const PUSH_NOTIFICATION = 'push-notifications';
const RECIEVE_NOTIFICATION = 'recieve-notification';
const JOIN_CHANNEL = 'join-channel';

const PUSH_MESSAGE = "push-message";
const RECIEVE_MESSAGE = "recieve-message";
// const EDIT_REMOVE_MESSAGE = "edit-remove-message";
const RECIEVE_EDIT_REMOVE_MESSAGE = "recieve-edit-remove-message";

const useNotifications = () => {
  // const { profile } = useSelector((state) => state._auth);
  // const dispatch = useDispatch();
  const [chatDeviceID, setChatDeviceID] = useState(
    localStorage.getItem("chatDeviceID")
  );
  const [messages, setMessages] = useState([]); // Sent and received messages
  const [isMoreActionMessage, setIsMoreActionMessage] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [countUnreadMessage, setCountUnreadMessages] = useState(0);
  const socketRef = useRef();


  useEffect(() => {
    const deviceID = localStorage.getItem("chatDeviceID");
    if (!deviceID) {
      const ID = generateDeviceID();
      localStorage.setItem("chatDeviceID", ID);
      setChatDeviceID(ID);
    }
  }, []);
  useEffect(() => {
    if (!chatDeviceID) return;
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: {},
    });

    socketRef.current.emit(JOIN_CHANNEL, { _id: chatDeviceID });

    // socketRef.current.on(RECIEVE_NOTIFICATION, async (payload) => {
    //   setNotifications((prev) => [payload, ...prev]);
    //   const { data } = await api.get('/users');
    //   dispatch(actions.initialLogin(data.data));
    //   dispatch({ type: 'OPEN' });
    // });

    socketRef.current.on(RECIEVE_MESSAGE, (payload) => {
      if (payload.action === "delete") {
        setMessages((prev) =>
          prev.filter((item) => item._id !== payload.message)
        );
      } else {
        setMessages((prev) => [...prev, payload]);
      }
      // getCountUnreadMessage();
    });

    socketRef.current.on(RECIEVE_EDIT_REMOVE_MESSAGE, (payload) => {
      setIsMoreActionMessage(true);
    });
    return () => {
      socketRef.current.disconnect();
    };
  }, [chatDeviceID]);

   const getCountUnreadMessage = async () => {
     console.log(123);
     try {
       const { data } = await api.get("/messages/unread");
       console.log(data);
       setCountUnreadMessages(data);
     } catch (error) {
       console.log(error);
     }
   };

  const pushNotifications = ({ to, message, description }) => {
    socketRef.current.emit(PUSH_NOTIFICATION, {
      to: to,
      message,
      description,
      status: false,
    });
  };

  const pushMessage = (message, type) => {
    socketRef.current.emit(PUSH_MESSAGE, {
      from: chatDeviceID,
      to: "root",
      role: "USER",
      message,
      type,
    });

    return {
      from: chatDeviceID,
      to: null,
      role: "USER",
      message,
      type,
      createdAt: new Date().getTime(),
    };
  };

  const pushBotMessage = (message, type) => {
    socketRef.current.emit(PUSH_MESSAGE, {
      from: null,
      to: chatDeviceID,
      role: "ROOT",
      message,
      type,
    });

    return {
      from: null,
      to: chatDeviceID,
      role: "ROOT",
      message,
      type,
      createdAt: new Date().getTime(),
    };
  };

  return {
    notifications,
    messages,
    setNotifications,
    setMessages,
    pushNotifications,
    pushMessage,
    countUnreadMessage,
    pushBotMessage,
    isMoreActionMessage,
    setIsMoreActionMessage,
  };
};

export default useNotifications;
