import TopContainer from "./components/Top";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import ChatPopup from "./components/ChatPopup";
import Chat from "./components/Chat";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import viVN from "antd/lib/locale/vi_VN";

import "./styles/App.scss";

function App() {
  return (
    <ConfigProvider locale={viVN}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div className="App">
                <TopContainer />
                <Header />
                <Main />
                <Footer />
                {/* <div className="chat-container">
                <ChatPopup />
              </div> */}
              </div>
            }
          />
          <Route path="/sos-ghtk" element={<Chat />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
